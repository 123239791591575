import React from 'react';
import {createRoot} from 'react-dom/client';
import configureStore from './configureStore';
import Root from './Root';
import { requestNotificationPermission } from './utils/notifications';
import settings from './utils/settings';

const store = configureStore();

// ask for notification permissions
requestNotificationPermission();

document.title = settings.getSetting('site_title');
let link =
    document.querySelector("link[rel*='icon']") || document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'shortcut icon';
link.href = process.env.REACT_APP_BASENAME + '/' + settings.getSetting('favicon');
document.getElementsByTagName('head')[0].appendChild(link);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<Root store={store} />);

