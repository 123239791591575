import { SettingsService } from "@brightsolutionsgmbh/client-core";

/**
 * Current settings by host name
 */
const settingsByHost = {
  default: {
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
    site_logo: "biologis.svg",
    favicon: "favicon-biologis.ico",
    client_id: "d9844058-4140-409d-ad4d-dbfde6b69bf3",
    client_secret: "e7eab7a7-95d4-47e8-b64f-dd2fd715b64a",
    security_code: "gim_biologis",
    servicedesk_user: "agentsmith",
    servicedesk_password: "Vouyeesh2sohngeivaikaed0Ue4eezaa",
  },
  localhost: {
    domain: "https://dm.prod.biologis.hosting.brightsolutions.de/prod",
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
  },
  "dm-webclient.dev.biologis.hosting.brightsolutions.de": {
    domain: "https://dm.dev.biologis.hosting.brightsolutions.de",
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
  },
  "dm-webclient.qa.biologis.hosting.brightsolutions.de": {
    domain: "https://dm.qa.biologis.hosting.brightsolutions.de",
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
  },
  "dm-webclient.stage.biologis.hosting.brightsolutions.de": {
    domain: "https://dm.stage.biologis.hosting.brightsolutions.de",
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
  },
  "dm.prod.biologis.hosting.brightsolutions.de": {
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
    domain: "https://dm.prod.biologis.hosting.brightsolutions.de/prod",
  },
  "zfh-dm.prod.biologis.hosting.brightsolutions.de": {
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
    domain: "https://zfh-dm.prod.biologis.hosting.brightsolutions.de/prod",
  },
  "ikp-dm.prod.biologis.hosting.brightsolutions.de": {
    site_name: "Robert-Bosch-Krankenhaus Stuttgart",
    site_title: "Pharmakogenetik RBK",
    site_logo: "rbkikp.png",
    favicon: "favicon-rbk.png",
    security_code: "KY6X",
    domain: "https://ikp-dm.prod.biologis.hosting.brightsolutions.de/prod",
  },
  "biologis-gims-dm.biologisgroup.com": {
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
  },
  "pharmasensor.net": {
    site_name: "bio.logis",
    site_title: "bio.logis - DM",
    domain: "https://pharmasensor.net/prod",
  },
  "pharmakogenetik.rbk.de": {
    site_name: "Robert-Bosch-Krankenhaus Stuttgart",
    site_title: "Pharmakogenetik RBK",
    site_logo: "rbkikp.png",
    favicon: "favicon-rbk.png",
    security_code: "KY6X",
    domain: "https://pharmakogenetik.rbk.de/prod",
  },
  "ikp-gims-dm.biologisgroup.com": {
    site_name: "Robert-Bosch-Krankenhaus Stuttgart",
    site_title: "Pharmakogenetik RBK",
    site_logo: "rbkikp.png",
    favicon: "favicon-rbk.png",
    security_code: "KY6X",
    domain: "https://ikp-gims-dm.biologisgroup.com/prod",
  },
};

/**
 * Get the setting depending on the current host name.
 *
 * @param setting_name
 * @returns {*}
 */
export default new SettingsService(settingsByHost, window.location.hostname);
