import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import PropTypes from "prop-types"; // ES6

import { Button } from "reactstrap";
import { connect } from "react-redux";
import { apiService } from "../../services/index";

class Feedback extends Component {
  sendFeedback = (e) => {
    e.preventDefault();
    window.location.href = "mailto:info@biologis.eu";
  };

  render() {
    const { intl } = this.props;

    return (
      <>
        <h1>
          <FormattedMessage id="feedback.title" />
        </h1>
        <Button color="primary" onClick={this.sendFeedback}>
          {intl.formatMessage({
            id: "feedback.openModalButton",
          })}
        </Button>
      </>
    );
  }
}

Feedback.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(
  connect(null, { createIssue: apiService.createIssue })(Feedback)
);
