import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Container } from 'reactstrap';
import { apiService } from '../../services';
import LoadingSpinner from '../LoadingSpinner';

export class DataTable extends Component {
  constructor(props) {
    super(props);
    this.tableWrapper = React.createRef();
    this.table = undefined;
    this.tableHeaders = undefined;
    this.parsedPlaceholders = [];
  }

  componentDidMount() {
    this.getTableData();
  }

  componentDidUpdate(prevProps) {
    if (
        this.props.tableKey !== prevProps.tableKey ||
        this.props.patientScope.patient_id !== prevProps.patientScope.patient_id
    ) {
      this.getTableData();
    }
  }

  getTableData = () => {
    const { lang, userId } = this.props;
    this.props.getTables(lang, userId).then(() => {
      this.makeTableSortable();
      this.addEventListenerToLinks();
      this.setTableHeight();
      this.addEventListener();
    });
  };

  addEventListener = () => {
    window.addEventListener('resize', this.setTableHeight);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.setTableHeight);
  }

  setTableHeight = () => {
    if (this.tableWrapper.current) {
      this.table = this.tableWrapper.current.querySelector('.table');

      if (this.table) {
        const tableBody = this.table.querySelector('tbody');

        if (tableBody) {
          // table body should be scrollable only on screens with min. 992px width
          if (window.innerWidth >= 992) {
            const viewPortHeight = Math.max(
                document.documentElement.clientHeight,
                window.innerHeight || 0
            );

            const tableHeight = viewPortHeight - 300;
            tableBody.style.height = `${tableHeight}px`;
          } else {
            tableBody.style.height = 'auto';
          }
        }
      }
    }
  };

  makeTableSortable = () => {
    if (this.tableWrapper.current) {
      this.table = this.tableWrapper.current.querySelector('.table');

      if (this.table) {
        this.tableHeaders = this.table.querySelectorAll('th');

        this.tableHeaders.forEach((header, index) => {
          header.addEventListener('click', () => {
            this.sortTable(this.table, index);
          });
        });
      }
    }
  };

  addEventListenerToLinks = () => {
    if (this.tableWrapper.current) {
      this.table = this.tableWrapper.current.querySelector('.table');

      if (this.table) {
        const links = this.table.querySelectorAll('.link');

        links.forEach((link, index) => {
          link.addEventListener('click', () => {
            this.search(this.parsedPlaceholders[index]);
          });
        });
      }
    }
  };

  search = data => {
    if (data.link_type === 'rec_search_by_ai_name') {
      this.props
          .getAutocomplete(
              { value: data.ai_name_translated_to_de },
              this.props.lang
          )
          .then(action => {
            if (!action.error) {
              const activeIngredient = action.payload.result[0];

              window.location.href = `${process.env.REACT_APP_BASENAME}/search/results?` +
                  new URLSearchParams({
                    searchParam: activeIngredient.value,
                    searchLabel: activeIngredient.label,
                    searchType: 'active_ingredient'
                  }).toString();
            }
          });
    } else if (data.link_type === 'gene_info') {
      console.log(data);
      window.location.href = `${process.env.REACT_APP_BASENAME}/my-dna/genes/${data.label}`;
    }
  };

  setSortDirectionCarets = (index, direction) => {
    this.tableHeaders.forEach(header => {
      header.classList.remove('asc', 'desc');
    });
    this.tableHeaders[index].classList.add(direction);
  };

  sortTable = (table, n) => {
    let rows,
        switching,
        i,
        x,
        y,
        shouldSwitch,
        dir,
        switchcount = 0;
    switching = true;
    // Set the sorting direction to ascending:
    dir = 'asc';
    /* Make a loop that will continue until
  no switching has been done: */
    while (switching) {
      // change sorting carets
      this.setSortDirectionCarets(n, dir);
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
    first, which contains table headers): */
      for (i = 1; i < rows.length - 1; i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
      one from current row and one from the next: */
        x = rows[i].getElementsByTagName('TD')[n];
        y = rows[i + 1].getElementsByTagName('TD')[n];
        /* Check if the two rows should switch place,
      based on the direction, asc or desc: */
        if (dir === 'asc') {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir === 'desc') {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
      and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        /* If no switching has been done AND the direction is "asc",
      set the direction to "desc" and run the while loop again. */
        if (switchcount === 0 && dir === 'asc') {
          dir = 'desc';
          switching = true;
        }
      }
    }
  };

  replacePlaceHolders = tableString => {
    const test = /\[\x\[.*?\]x\]/g;
    return tableString.replace(test, this.convertPlaceholderToLink);
  };

  convertPlaceholderToLink = str => {
    const jsonString = str.substring(3, str.length - 3);
    const parsedString = JSON.parse(jsonString);
    this.parsedPlaceholders.push(parsedString);
    return `<button class="btn btn-link link">${parsedString.label}</button>`;
  };

  render() {
    const { tableData, match, isFetching, patientScope } = this.props;
    let styledTable = (tableData || '').replace(
        'table',
        'table class="table table-striped"'
    );
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split('/');
    const pageTitle = pathSegments.slice(-1)[0];
    styledTable = this.replacePlaceHolders(styledTable);

    return (
        <>
          {isFetching || patientScope.isSetting || patientScope.isFetching ? (
              <LoadingSpinner messageId="myDna.loadingMessage" />
          ) : (
              <Container className="ml-0 p-5">
                <div className={`data-table ${pageTitle}`} ref={this.tableWrapper}>
                  <h1 className="mb-4">
                    <FormattedMessage id={`navbar.${pageTitle}`} />
                  </h1>
                  <p>
                    <FormattedMessage id={`myDna.descriptions.${pageTitle}`} />
                  </p>
                  {styledTable ? (
                      <div
                          dangerouslySetInnerHTML={{
                            __html: styledTable
                          }}
                      />
                  ) : (
                      <FormattedMessage id="myDna.noContent" />
                  )}
                </div>
              </Container>
          )}
        </>
    );
  }
}

DataTable.propTypes = {
  getTables: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  tableData: PropTypes.string,
  tableKey: PropTypes.string.isRequired,
  getAutocomplete: PropTypes.func.isRequired,
  match: PropTypes.object,
  isFetching: PropTypes.bool,
  patientScope: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.user.data.id,
    lang: state.lang.lang,
    tableData: state.tables.data[ownProps.tableKey],
    isFetching: state.tables.isFetching,
    patientScope: state.patientScopeSwitcher.patientScope
  };
};

export default connect(mapStateToProps, {
  getTables: apiService.getTables,
  getAutocomplete: apiService.getAutocomplete
})(DataTable);
